import * as React from 'react';
import { graphql } from 'gatsby';
import Blog, {
  BlogSubMiniHeader,
  BlogSubHeader,
  BlogList,
  BlogListItem,
} from '../../components/Blog';
import { Paragraph } from '../../components/Common';
import { HE } from 'src/blogs/software-development-tools-that-must-be-familiar';
import {
  SoftwareDevelopmentToolsThatMustBeFamiliar,
  LeadingTrendsInWebApplicationDevelopment,
  WinningSitesBuiltUsingReact,
  FrequentlyAskedQuestionsAndExamplesOfSaas,
} from '../../blogs';
import { useBreadcrumbsNesteadSchema } from '../../hooks/useBreadcrumbsNesteadSchema';
import { BLOGS_ORIGINAL_BREADCRUMBS } from '../../constants';
import { useArticelSchema } from '../../hooks/useArticelSchema';

const title = "Software Development Tools That Must Be Familiar";

const articleSchemaData = {
  headline: title,
  description: "SEO description Software Development Tools That Must Be Familiar",
  image: "https://firebasestorage.googleapis.com/v0/b/site-ab026.appspot.com/o/blogs%2Fsoftware-development-tools-that-must-be-familiar.jpg?alt=media&token=41c9f55d-8cc1-4cbd-86f9-bec18dd5a6fd",
  datePublished: '2022-03-05'
};


const SoftwareDevelopmentToolsThatMustBeFamiliarBlog = () => {
  const breadcrumbsElementsSchema = useBreadcrumbsNesteadSchema(title, BLOGS_ORIGINAL_BREADCRUMBS);
  const articelSchema = useArticelSchema(articleSchemaData);

  return (
    <Blog
      url={SoftwareDevelopmentToolsThatMustBeFamiliar.url}
      banner="/software-development-tools-that-must-be-familiar/banner.jpg"
      relatedBlogs={[
        LeadingTrendsInWebApplicationDevelopment,
        FrequentlyAskedQuestionsAndExamplesOfSaas,
        WinningSitesBuiltUsingReact,
      ]}
      title={title}
      seo={{
        title: 'SEO title Software Development Tools That Must Be Familiar',
        description:
          'SEO description Software Development Tools That Must Be Familiar',
        image:
          'https://firebasestorage.googleapis.com/v0/b/site-ab026.appspot.com/o/blogs%2Fsoftware-development-tools-that-must-be-familiar.jpg?alt=media&token=41c9f55d-8cc1-4cbd-86f9-bec18dd5a6fd',
        breadcrumbsElementsSchema,
        articelSchema,
      }}
    >
      {HE.section1.p.map((p) => (
        <Paragraph>{p}</Paragraph>
      ))}

      <BlogSubHeader>{HE.section2.h}</BlogSubHeader>
      {HE.section2.p.map((p) => (
        <Paragraph>{p}</Paragraph>
      ))}

      <BlogList>
        {HE.section3.ol.map((item) => (
          <BlogListItem>
            <BlogSubMiniHeader>{item.h3}</BlogSubMiniHeader>
            {item.p.map((p) => (
              <Paragraph>{p}</Paragraph>
            ))}
          </BlogListItem>
        ))}
      </BlogList>

      {HE.section4.p.map((p) => (
        <Paragraph>{p}</Paragraph>
      ))}
    </Blog>
  );
}

export default SoftwareDevelopmentToolsThatMustBeFamiliarBlog;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
