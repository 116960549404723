import React from 'react';
import { Link } from 'src/components/Common';
import { URL } from '../url';

export const HE = {
  section1: {
    p: [
      <>
        {
          'אפספורס (AppsForce) היא חברת פיתוח תוכנה מובילה המתמחה בטכנולוגיות קצה ובמתודולוגיות עבודה מתקדמות ואיכותיות. פיתוח תוכנה על ידי המומחים המנוסים שלנו מבטיח לכם את המוצר המושלם והמתקדם ביותר מבחינה טכנולוגית ומבחינת '
        }
        <Link to={URL.UI_UX_DESIGN_SERVICE} active>
          עיצוב ואפיון ממשקי UI/UX.
        </Link>
        {
          ' כדי לפתח ללקוחות שלנו את המוצרים המושלמים אנו עושים שימוש במגוון כלי פיתוח, ובמאמר זה נציג בפניכם 7 כלים מתקדמים לפיתוח אפליקציות, מערכות WEB ופיתוח תוכנות.'
        }
      </>,
    ],
  },
  section2: {
    h: 'רקע כללי על כלי פיתוח תוכנה',
    p: [
      'תחום הפיתוח עבר שינויים רבים במרוצת השנים, וכיום יש למפתחים מגוון כלי עזר לפיתוח אתרים, תוכנות ואפליקציות. הכלים השונים מסייעים לקהילת המפתחים מאחר והם חוסכים זמן יקר בפרויקט הפיתוח ובכתיבת הקוד, הם מסייעים באיתור באגים במערכת ובנוסף הם חוסכים משאבים רבים בפיתוח התוכנה ללקוח.',
      'יש אלפי תוכנות וכלי עזר למפתחים, וגם ענקיות הטכנולוגיה פועלות בשוק זה ומציעות כלי פיתוח מתקדמים וחדישים. לכן, בחירת הכלים היא משימה בפני עצמה, אך יש כלי פיתוח הנחשבים לחובה עבור המפתחים, ובכתבה זו נציג 7 כלים שאנו ממליצים עליהם.',
    ],
  },
  section3: {
    ol: [
      {
        h3: '1. Node.JS',
        p: [
          "פלטפורמת פיתוח מתקדמת המבוססת על מנוע של ג'אווה סקריפט מבית גוגל, הפלטפורמה הייעודית מהווה כלי פיתוח מצוין לפיתוח אפליקציות העוסקות בטיפול אינטנסיבי בנתונים ובמידע, בזמן אמת, ועם מעט חישובים. ראוי לציין כי עקב המעבר לפיתוח אפליקציות המבוססות על JavaScript וארכיטקטורת API, רבים מהמפתחים אימצו את פלטפורמת הפיתוח של Node.JS ובשנים האחרונות היא זוכה לפופולריות גבוהה בקהילת המפתחים.",
        ],
      },
      {
        h3: '2. Angular',
        p: [
          <>
            {
              'אנגולר (Angular) הוא פריימוורק למפתחים בסביבת WEB, הכלי מאפשר לפתח צד לקוח, כלומר '
            }
            <Link to={URL.FRONT_END_SERVICE} active>
              פיתוח Front End
            </Link>
            {
              " באופן מהיר, ובצורה מודולרית ונוחה. מפתחי ווב רבים המתמחים בטכנולוגיות ובכלי פיתוח מהדור הקודם עברו לפיתוח באנגולר בגלל הטכנולוגיה החדשנית מבית גוגל והתהליך הנוח יותר לפיתוח רכיבי התוכנה, כאשר יש למפתח ספריית ג'אווה סקריפט עשירה במיוחד המסייעת לו לכתוב את הקוד במהירות וביעילות."
            }
          </>,
        ],
      },
      {
        h3: '3. React',
        p: [
          "ריאקט היא ספריית ג'אווה סקריפט של ענקית הטכנולוגיה פייסבוק, היא מתאימה לבניית ממשקי משתמש ומאפשרת למפתחים לבנות יישומי אינטרנט מורכבים וגדולים המשתמשים בנתונים משתנים, ללא צורך בטעינת הדף מחדש. זהו כלי פיתוח חובה למפתחים העוסקים בפיתוח ובבניית יישומים חכמים ואפליקציות מתקדמות.",
        ],
      },
      {
        h3: '4. GIT',
        p: [
          'גיט (GIT) היא מערכת מבוזרת המיועדת לניהול גרסאות, כיום היא נחשבת לפלטפורמה המועדפת והפופולרית ביותר בתחום הפיתוח. בעזרת הכלי ניתן לעבוד ולפתח תוכנה כיחיד או לעסוק בפיתוח כצוות, כאשר המערכת מאפשרת מעקב נוח אחר השינויים שבוצעו בקוד של הפרויקט עליו עובדים.',
        ],
      },
      {
        h3: '5. Bootstrap',
        p: [
          'כלי פיתוח תוכנה הבנוי על קוד פתוח, זהו כלי מתקדם המאפשר למתכנתים לבנות אתרים ואפליקציות WEB. המערכת כוללת שלושה חלקים מרכזיים – רכיב ה- CSS לעיצוב דפי אינטרנט ותבניות, רכיב לבניית ממשק ידידותי למשתמש כולל כפתורים, תמונות הודעות ועוד, והרכיב השלישי במערכת הוא הסקריפטים הרבים הכלולים בתוכנה. בעזרת פיתוח ב- Bootstrap קל להתאים את האתרים גם למסכים הקטנים של הטלפונים החכמים, כלומר קל להקים אתרים רספונסיביים המתאימים למובייל.',
          'כדאי לדעת כי Bootstrap פותחה על ידי טוויטר במטרה להתמודד עם חוסר העקביות של ממשקי ה- UI, ובאוגוסט 2011 הכלי שוחרר כ-Open Source וקהילת המפתחים אימצה אותו, בעיקר לצורך פיתוח אתרים קטנים עם עיצוב מקורי וייחודי.',
        ],
      },
      {
        h3: '6. jQuery',
        p: [
          "jQuery היא ספריית ג'אווה סקריפט שמסייעת למפתחים לכתוב ולתכנת סקריפטים לצד לקוח, הספרייה נתמכת על ידי דפדפנים שונים. jQuery היא כלי חשוב לפיתוח תוכנה שהופץ לראשונה בשנת 2006. זו תוכנה חופשית בקוד פתוח הכוללת רישיונות GPL GNU ו- MIT. הכלי מפשט את האפשרות לנווט במסמכים, לבחור אלמנטים ל- DOM בצורה יעילה ונוחה, ליצור אנימציות, לטפל באירועים שנים ולבנות אפליקציות מתקדמות המבוססות על AJAX.",
          'הכלי מאפשר לפתח דפי אינטרנט דינמיים ויישומי רשת מתקדמים ורבי עוצמה, בצורה מהירה וקלה יותר, בזכות הגישה המודולרית של התוכנה.',
        ],
      },
      {
        h3: '7. Resharper',
        p: [
          'כלי פרודוקטיבי וחשוב למפתחי NET, הכלי מסייע למפתחים לכתוב ולשפר קוד מקור של מגוון כלי פיתוח קיימים ונפוצים בשוק כמו VB, ASP.Net, CSS, XML, HTML, ולמעשה הוא הופך את הוויזואל סטודיו מבית היוצר של מיקרוסופט ל- IDE והמפתחים יכולים לעבוד ביעילות ובנוחות, לתחזק את הקוד בקלות, לבחור שיטות קידוד טובות יותר ולהשלים פרויקטים ויישומים איכותיים במהירות.',
          <>
            {' הכלי Resharper פותח על ידי חברת '}
            <Link external={'https://www.jetbrains.com/'} active>
              Jetbrains
            </Link>
            {
              ', חברה מובילה לפיתוח כלי תוכנה לקהילת המפתחים. התוכנה זוכה לעדכונים שוטפים ומפתחים רבים אימצו אותה ככלי לפיתוח יישומים באיכות גבוהה.'
            }
          </>,
        ],
      },
    ],
  },
  section4: {
    p: [
      <>
        {'לסיכום, '}
        <Link to={URL.HOME} active>
          חברת פיתוח תוכנה מובילה כמו אפספורס
        </Link>
        {
          ' מאמצת את הכלים הטכנולוגיים החדשניים והמתקדמים ביותר על מנת לייצר עבורכם פתרונות מותאמים אישית. אנשי הפיתוח המנוסים שלנו משתמשים במגוון רחב של כלי פיתוח על מנת להבטיח לכם מוצר טכנולוגי חדשני ואיכותי העונה בדיוק לדרישות ולצרכים הייחודים שלכם.'
        }
      </>,
    ],
  },
};
